/* --- Reset css for consistent behavior on different browsers --- */
html, body {
    background-color: white;
    height: 100%;
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

ol, ul {
    list-style: none;
}

a {
    color: rgb(33, 95, 171);
    text-decoration: none;
}

img, embed, object, video {
    max-width: 100%;
    height: auto;
}

.container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
    flex-flow: column;
    align-items: stretch;
    -webkit-align-items: stretch;

    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

/* .hero-picture {
    background-image: url(./img/vietnam_future.jpeg);
} */